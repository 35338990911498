<template>
  <div class="redemptionlist-style">
    <div class="outbox">
      <el-card class="box-card">
        <div class="box1">{{$t('text.t224')}}</div>
        <div class="box2 between-center">
          <div class="box2-item">{{$t('text.t213')}}</div>
          <div class="box2-item">{{$t('text.t127')}}</div>
          <div class="box2-item">{{$t('text.t118')}}</div>
        </div>
        <div>
          <van-list
            v-model="loading"
            :finished="finished"
            :finished-text="$t('text.t39')"
            :offset="10"
            @load="getlist"
          >
            <div
              class="box3 between-center"
              v-for="item in list"
              :key="item.id"
            >
              <div class="box3-item">Ture NFT#{{item.tokenid}}</div>
              <div class="box3-item">{{item.number}}</div>
              <div class="box3-item">{{$parseTime(item.createtime,"{y}/{m}/{d}")}}</div>
            </div>
          </van-list>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      list: [],
      loading: false,
      finished: false,
      page: 1,
      limit: 20,
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getlist() {
      let arg = {
        page: this.page,
        limit: this.limit,
        type: 1,
      };
      this.$http.get("/redeem/pledgeRedeemLog", arg).then((res) => {
        const { current_page, data, last_page } = res.data;
        if (current_page >= last_page) this.finished = true;
        this.page++;
        this.loading = false;
        this.list = [...this.list, ...data];
      });
    },
  },
};
</script>

<style lang="less">
.redemptionlist-style {
  .outbox {
    padding: 20px 0;
    .box-card {
      width: 350px;
      border-radius: 20px;
      margin: auto;
      .box1 {
        font-size: 15px;
        font-weight: bold;
        color: #323232;
        margin-bottom: 25px;
        text-align: center;
      }
      .box2 {
        font-size: 12px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        margin-bottom: 5px;
        .box2-item {
          width: 33.3%;
        }
      }
      .box3 {
        font-size: 9px;
        font-weight: bold;
        color: #323232;
        text-align: center;
        padding: 16px 0;
        border-bottom: 1px solid #e1e1e1;
        .box3-item {
          width: 33.3%;
        }
      }
    }
  }
}
</style>